/* todo: add media queries for height changes*/
/* todo: split styles into modules */
.display-none {
  display: none !important;
}

.PhoneInputInput {
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 7px;
}

/* MINIMIZED DESKTOP SCREEN ? */
@media only screen and (min-width: 768px) and (max-width: 1286px) {
  /* #sidebar svg {
    width: 18px;
    height: 18px;
  }  */

  #sidebar #sidebar-links {
    font-size: 13px;
  }
}

/* PHONES */
@media only screen and (max-width: 767px) {
  #sidebar {
    display: none !important;
  }

  /*
   ,
  #chat_messages-area
  #chat_details-area,
   */
}

.cursor-pointer {
  cursor: pointer;
}

.background-white {
  background: #fff;
}

/* DISABLED ICONS */
svg.disabled > g > g {
  filter: none;
}

svg.disabled {
  opacity: 0.3;
}

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
